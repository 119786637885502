import React from "react";
import { Link, useParams } from "react-router-dom";

const Confirmation = () => {
    const { location, day, slot } = useParams();
    
    return (
        <div className="confirmation-container">
        <h1>Confirmation</h1>
        <p>
            You have booked a slot at {location} on {day} at {slot}
        </p>
        <Link to="/">Go back to home</Link>
        </div>
    );
    }

export default Confirmation;