import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LocationList from './LocationList';
import BookingList from './BookingList';
import Confirmation from './Confirmation';
import Payment from './Payment';
import Header from './Header';
import Login from './Login';
import './App.css';
import MyBookings from './MyBookings';



const App = () => {
  console.log('MY ENV', process.env.REACT_APP_BRANCH_NAME)
  return (
    <div className='bg-gray-100 h-screen'>
    <Router>
    <Header/>
      <Routes>
        <Route path="/" element={<LocationList />} />
        <Route path="/login" element={<Login />} />
        <Route path="/bookings/:location" element={<BookingList />} />
        <Route path="/payments/" element={<Payment />} />
        <Route path="confirmation/:location/:date/:slot" element={<Confirmation/>} />
        <Route path="/bookings" element={<MyBookings />} />
        <Route path="*" element={<LocationList />} />
      </Routes>
    </Router></div>
  );
};
export default App;
