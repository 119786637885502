import PropTypes from "prop-types";
import "./Booking.css";
import axios from "axios";
import { apiEndpoint } from "./vars";
const ENDPOINT = apiEndpoint;

const Booking = ({ booking, onBookingDeleted }) => {
  const handleDeleteClick = async () => {
    try {
      const response = await axios.delete(
        `${ENDPOINT}bookings?id=${booking.id}&date=${booking.date}`
      );
      console.log(response.data);
      onBookingDeleted();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg mb-4">
      <div className="text-lg font-semibold mb-2">Location: {booking.location}</div>
      <div className="text-md font-medium mb-2">Date: {booking.date}</div>
      <div className="text-md font-medium mb-4">Time: {booking.slot}</div>
      <div className="flex justify-end">
        <button 
          onClick={handleDeleteClick}
          className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

Booking.propTypes = {
  booking: PropTypes.shape({
    location: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    slot: PropTypes.string.isRequired,
  }).isRequired,
  onBookingDeleted: PropTypes.func.isRequired,
};

export default Booking;
