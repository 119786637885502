import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LocationList.css';
import logo from "./logo.jpg";
import { FaStar } from 'react-icons/fa';

const LocationList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const locations = [
    {
      name: "Baker Street Dentist",
      rating: 5,
      address: "221b Baker Street, London, NW1 6XE",
      imageUrl: "https://www.cochrane.org/sites/default/files/public/styles/social_media/public/uploads/news/young-confident-asian-male-dentist-medical-treatment-to-a-female-patient-at-the-clinic.-dental-clinic-concept-911844046_1257x838_3.jpeg?itok=Gf5izRh3"
    },
    {
      name: "Tower of London Hairdressers",
      rating: 4,
      address: "1 Tower of London, London, EC3N 4AB",
      imageUrl: "https://www.williamsons-solicitors.co.uk/wp-content/uploads/2017/03/hairdressers-633x420.jpg"
    },
    {
      name: "BMW Car Garage",
      rating: 5,
      address: "32 Park Lane, London, W1K 1BE",
      imageUrl: "https://images.ctfassets.net/e38ztolm3cqc/2f2NsnX7LSnrkihHsOdWBX/d5e659a8c8f96b2179b8b68dbae710a9/capture2.jpg?fm=jpg&fl=progressive&q=80"
    },
    {
      name: "The Strand Opticians",
      rating: 3,
      address: "54 The Strand, London, WC2N 5HX",
      imageUrl: "https://c.yell.com/t_galleryFit,f_auto/fe2c2ca1-5a8e-4b20-82ea-8b3fc3a86e00_image_jpeg.jpg"
    },
    {
      name: "Westminster Abbey Gift Shop",
      rating: 2,
      address: "20 Deans Yard, London, SW1P 3PA",
      imageUrl: "https://www.westminster-abbey.org/media/10652/shop-exterior.jpg?center=0.47630922693266831,0.58166666666666667&mode=crop&width=1024&height=512&rnd=132436811750000000"
    },
    {
      name: "National Gallery Cafe",
      rating: 4,
      address: "Trafalgar Square, London, WC2N 5DN",
      imageUrl: "https://via.placeholder.com/150"
    },
    {
      name: "British Museum Gift Shop",
      rating: 3,
      address: "Great Russell St, London, WC1B 3DG",
      imageUrl: "https://via.placeholder.com/150"
    },
    {
      name: "St. Paul's Cathedral Cafe",
      rating: 4,
      address: "St. Paul's Churchyard, London, EC4M 8AD",
      imageUrl: "https://via.placeholder.com/150"
    }
  ];

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const filteredLocations = locations.filter(location => {
    return location.name.toLowerCase().includes(searchTerm.toLowerCase());
  });


  function getStarRating(rating) {
    const starRating = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        starRating.push(<FaStar key={i} className="text-yellow-400" />);
      } else {
        starRating.push(<FaStar key={i} className="text-gray-400" />);
      }
    }
    return starRating;
  }


  return (
    <div className="bg-gray-100 px-4 py-8">
      <input
        type="text"
        placeholder="Search for a location"
        value={searchTerm}
        onChange={handleSearch}
        className="block w-full px-4 py-2 mb-4 leading-tight border rounded-md appearance-none focus:outline-none focus:border-blue-500"
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredLocations.map(location => (
          <Link key={location.name} to={`/bookings/${location.name}`} className="block bg-white border rounded-md shadow-md hover:shadow-lg">
            <div className="flex items-center justify-center bg-gray-300 h-72">
              <img src={location.imageUrl} alt="Logo" className="h-full w-full object-cover" />
            </div>
            <div className="p-3 text-gray-900 font-medium">{location.name}</div>
            <div className="p-3 flex items-center text-gray-600 text-sm">{getStarRating(location.rating)}</div>
            <p className='p-3 text-gray-600 text-sm'>{location.address}</p>
          </Link>
        ))}
      </div>



    </div>
  );
};

export default LocationList;
