import React, {useEffect} from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
const Header = () => {
    const navigate = useNavigate();

    // Get id_token form url

    const urlParams = new URLSearchParams(window.location.hash.substring(1)); // remove the "#" symbol at the beginning
    const idToken = urlParams.get('id_token');
    // save id_token to local storage
    localStorage.setItem('idToken', idToken);
    
    console.log(idToken);
    // redirect to home page
    useEffect(() => {
        navigate(`/`);
    }, [navigate]);
    
    return (
    <div className="header">
    </div>
  );
};

export default Header;
