import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Payment.css";
import axios from "axios";

const Payment = () => {
  const location = new URLSearchParams(window.location.search).get("location");

  const urlParams = new URLSearchParams(window.location.hash.substring(1)); // remove the "#" symbol at the beginning


  const slot = new URLSearchParams(window.location.search).get("slot");
  const date = new URLSearchParams(window.location.search).get("date");


  const [token, setToken] = useState(null);
  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("idToken"));
    setIdToken(urlParams.get('id_token'));
    localStorage.setItem('idToken', idToken);
    console.log(idToken)
    console.log(token)
  }, [idToken, token, urlParams]);


  // save id_token to local storage
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const ENDPOINT = "https://api.booksmartapp.co.uk/prod/";



  if (!idToken || idToken === "null") {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-3xl font-bold mb-8">Please log in to make a booking.</h1>
        <button
          className="px-6 py-3 bg-blue-500 text-white rounded-md font-medium hover:bg-blue-600 transition-colors duration-200"
          onClick={() =>
          (window.location.href =
            "https://auth.booksmartapp.co.uk/login?client_id=527jee4f7418gfrfl77ed9kcsq&response_type=token&scope=email+openid&redirect_uri=https://booksmartapp.co.uk/payments/&state=" +
            location +
            "." +
            date +
            "." +
            slot)
          }
        >
          Log in
        </button>
      </div>
    );
  }

  const state = urlParams.get('state')
  const stateArray = state.split('.')
  console.log(stateArray)
  const stateLocation = stateArray[0]
  const stateDate = stateArray[1]
  const sateSlot = stateArray[2]


  function parseJwt(token) {
    try {
      if (!token) {
        return "Not Logged in";
      }
      if (token === "null") {
        return "Not Logged in";
      }
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const email = JSON.parse(window.atob(base64)).email;
      console.log(email);
      return email;
    } catch (error) {
      console.log("Error parsing JWT", error);
      return;
    }
  }



  const postPayment = async () => {
    setIsLoading(true);
    try {
      const booking = {
        location: stateLocation,
        date: stateDate,
        slot: sateSlot,
        slotEnd: sateSlot.slice(0, 2) + ":00",
        userId: parseJwt(idToken),
      };
      const postBooking = async () => {
        try {
          const token = localStorage.getItem("idToken");
          const config = {
            headers: {
              token: token,
            },
          };
          const response = await axios.post(`${ENDPOINT}bookings`, booking, config);
          console.log(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      postBooking();

      // Redirect to a confirmation page if payment is successful
      navigate(`/confirmation/${stateLocation}/${stateDate}/${sateSlot}`);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="payment-container bg-gray-100 p-6">
      <h1 className="text-2xl font-bold mb-4">Booking for {stateLocation}</h1>
      <p className="mb-2">
        Day: {stateDate}, Slot: {sateSlot}
      </p>
      <form class="w-full max-w-lg">
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="name">
            Name*
          </label>
          <input class="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="John Doe"></input>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="email">
            Email*
          </label>
          <input class="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" disabled={true} defaultValue={parseJwt(idToken)}></input>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="phone">
            Phone*
          </label>
          <input class="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="tel" placeholder="(123) 456-7890"></input>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="date">
            Date
          </label>
          <input class="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" disabled={true} id="date" type="date" defaultValue={(stateDate)}></input>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="time">
            Time
          </label>
          <input class="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" disabled={true} defaultValue={sateSlot} type="text"></input>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="message">
            Message
          </label>
          <textarea class="appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" defaultValue={slot} placeholder="Enter your message here"></textarea>
        </div>
        <div class="flex items-center justify-between">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={postPayment}>Book Now</button>
        </div>
      </form>
    </div>
  );

};

export default Payment;
