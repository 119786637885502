import React from "react";
import { useParams } from "react-router-dom";
import BookingCal from "./BookingCal";

const BookingList = () => {
  const { location } = useParams();
  return (
    <div className="flex flex-col items-center bg-gray-200">
      <h1 className="text-4xl font-bold mt-10 mb-5">{location}</h1>
      <BookingCal />
    </div>
  );
};

export default BookingList;
